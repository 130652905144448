<template>
    <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="validate">
        <v-container grid-list-xl>
            <v-layout wrap>
                <v-flex xs12>
                    <v-switch v-model="item.enable" :label="$t('Habilitado')" class="my-0 py-0" />
                </v-flex>
                <v-flex xs12>
                    <v-text-field :label="$t('Nome')" v-model="item.name" :rules="[rules.required, rules.min(10)]" />
                </v-flex>
                <v-flex xs12 md12 sm12 v-if="!item.id">
                    <v-text-field :label="$t('E-mail')" v-model="item.email" :rules="[rules.required, rules.email]" />
                    <v-text-field
                        :label="$t('Digite a senha')"
                        v-model="item.password"
                        min="8"
                        :rules="[rules.required, rules.password]"
                        :append-icon="show1 ? 'visibility' : 'visibility_off'"
                        :type="show1 ? 'text' : 'password'"
                        @click:append="show1 = !show1"
                        counter
                        required
                    />
                    <v-text-field
                        :label="$t('Confirme a senha')"
                        v-model="confirmPassword"
                        min="8"
                        :rules="confirmPasswordRules"
                        :append-icon="show2 ? 'visibility' : 'visibility_off'"
                        :type="show2 ? 'text' : 'password'"
                        @click:append="show2 = !show2"
                        counter
                        required
                    />
                </v-flex>
                <v-flex xs12 md12 sm12 v-else>
                    <v-text-field :label="$t('E-mail')" v-model="item.email" disabled />
                </v-flex>

                <v-flex xs12>
                    <v-autocomplete v-model="item.stores" :items="stores" item-value="id" :label="$t('Lojas')" multiple>
                        <template v-slot:item="data">
                            <v-list-item-content>
                                <v-list-item-title v-html="data.item.name"></v-list-item-title>
                                <v-list-item-subtitle v-html="data.item.city"></v-list-item-subtitle>
                            </v-list-item-content>
                        </template>
                        <template v-slot:selection="data">
                            <v-list-item-content>
                                <v-list-item-title v-html="data.item.name"></v-list-item-title>
                                <v-list-item-subtitle v-html="data.item.city"></v-list-item-subtitle>
                            </v-list-item-content>
                        </template>
                    </v-autocomplete>
                </v-flex>

                <v-flex xs12>
                    <v-autocomplete
                        v-model="item.roles"
                        :items="roles"
                        item-value="key"
                        item-text="description"
                        :label="$t('Regras')"
                        class="my-0 py-0"
                        deletable-chips
                        multiple
                        small-chips
                    ></v-autocomplete>
                </v-flex>
                <v-flex xs12 md12 sm12>
                    <v-btn :loading="lock" :disabled="!valid" color="success" @click="validate">{{ $t("Salvar") }}</v-btn>
                </v-flex>
            </v-layout>
        </v-container>
    </v-form>
</template>

<script>
import { mask } from "vue-the-mask";
import rules from "@/helpers/rules";

export default {
    directives: { mask },

    props: {
        itemToEdit: {
            type: Object,
        },
        lock: {
            type: Boolean,
            required: true,
        },
    },

    data() {
        return {
            rules,
            valid: true,
            confirmPassword: null,
            show1: false,
            show2: false,
            itemClean: {
                name: "",
                email: "",
                password: "",
                enable: true,
                stores: [],
                roles: ["USER"],
            },
            stores: [],
            item: { ...this.itemClean },
            roles: [
                { key: "USER", description: this.$t("Usuário") },
                { key: "DOCTOR", description: this.$t("Médico") },
                { key: "PRE_DOCTOR", description: this.$t("Médico (Pendente)") },
                { key: "ATTENDANT", description: this.$t("Atendimento") },
                { key: "RESPONSIBLE", description: this.$t("Responsável Técnico") },
                { key: "FINANCIAL", description: this.$t("Financeiro") },
                { key: "ADMIN", description: this.$t("Administrador") },
            ],
            confirmPasswordRules: [(v) => v === this.item.password || this.$t("Senha diferentes")],
        };
    },

    watch: {
        itemToEdit: {
            immediate: true,
            handler(newVal) {
                if (this.$refs.form) {
                    this.$refs.form.reset();
                }

                this.$nextTick(() => {
                    if (newVal == null) {
                        this.item = { ...this.itemClean };
                    } else {
                        this.item = { ...newVal };
                    }
                });
            },
        },
    },

    methods: {
        validate() {
            if (this.$refs.form.validate()) {
                this.$emit("save", this.item);
            }
        },
    },

    mounted() {
        if (this.$refs.form) {
            this.$refs.form.reset();
        }

        this.$http
            .get(`stores`)
            .then((result) => (this.stores = result))
            .catch(() => (this.stores = []));
    },
};
</script>